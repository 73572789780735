import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { motion } from 'framer-motion'

import { Layout } from '../components'
import { Fade } from '../utils'

import { container, padding, bgImage, bgIcon } from '../styles/global'
import { media, useBreakpoint } from '../styles/utils'
import {
    heading1,
    heading2,
    subheading2,
    heading3,
    links,
    body,
    subheading1,
    caption,
} from '../styles/type'
import {
    brown1,
    brown3,
    blue2,
    brown4,
    brown2,
    brown5,
    blue1,
    blue3,
    blue4,
    grey1,
    grey2,
    grey3,
} from '../styles/colors'

const renderTopSidebar = data => {
    // Roles
    const rolesOverride = data.acf.role_override
    const rolesData = data.acf.roles
    let roles = undefined

    if (rolesOverride) {
        roles = rolesOverride
    } else {
        roles = data.acf.roles.map((role, i) => {
            return role.name
        })
    }
    return (
        <Sidebar>
            {useBreakpoint('phone') && <MobileHeading>Team</MobileHeading>}
            <Heading>{roles}</Heading>
        </Sidebar>
    )
}

const renderTopMain = data => {
    return (
        <Main>
            <Heading>{data.title}</Heading>
            <Image
                key={data.acf.headshot.ID}
                src={data.acf.headshot.sizes && data.acf.headshot.sizes.medium2}
            >
                {(src, loading) => {
                    return (
                        <BGImage
                            image={src}
                            style={{ opacity: loading ? 0 : 1 }}
                        />
                    )
                }}
            </Image>
        </Main>
    )
}

const renderBottomSidebar = data => {
    return (
        <Sidebar>
            {!useBreakpoint('phone') && <Heading>{data.title}</Heading>}
            <Info>
                {data.acf.telephone && (
                    <Phone>
                        <Heading>Telephone</Heading>
                        <ExternalLink href={`tel:${data.acf.telephone}`}>
                            {data.acf.telephone}
                        </ExternalLink>
                    </Phone>
                )}

                {data.acf.mobile && (
                    <Phone>
                        <Heading>Mobile</Heading>
                        <ExternalLink href={`tel:${data.acf.mobile}`}>
                            {data.acf.mobile}
                        </ExternalLink>
                    </Phone>
                )}

                {data.acf.email && (
                    <Item>
                        <Heading>Email</Heading>
                        <ExternalLink href={`mailto:${data.acf.email}`}>
                            {data.acf.email}
                        </ExternalLink>
                    </Item>
                )}

                {data.acf.linkedin && (
                    <Item>
                        <Heading>Linkedin</Heading>
                        <ExternalLink
                            href={data.acf.linkedin}
                            target={'_blank'}
                        >
                            {data.title}
                        </ExternalLink>
                    </Item>
                )}
            </Info>
        </Sidebar>
    )
}

const renderMoreTeam = data => {
    const items = data.acf.more_team.map((item, i) => {
        const image = item.person.headshot
        const first_name = item.person.first_name
        const last_name = item.person.last_name
        const rolesOverride = item.person.role_override
        const rolesData = item.person.roles
        let roles = undefined

        if (rolesOverride) {
            roles = rolesOverride
        } else {
            roles = item.person.roles.map((role, i) => {
                return role.name
            })
        }


        if (!image) return;

        return (
            <TeamLink key={i} to={`/team/${item.person.slug}`}>
                <Transition key={image.ID} whileHover={'hover'}>
                    <ImageWrapper>
                        <TeamImage
                            key={image.ID}
                            src={image.sizes ? image.sizes.medium2 : null}
                        >
                            {(src, loading) => {
                                return (
                                    <TeamBGImage
                                        image={src}
                                        key={image.ID}
                                        {...animatedImage}
                                    />
                                )
                            }}
                        </TeamImage>
                    </ImageWrapper>

                    <TeamDescription key={image.ID} {...animatedDescription}>
                        <Roles>{roles}</Roles>

                        <FirstName
                            key={image.first_name}
                            {...animatedLink}
                            dangerouslySetInnerHTML={{
                                __html: first_name,
                            }}
                        />

                        <LastName
                            key={image.last_name}
                            {...animatedLink}
                            dangerouslySetInnerHTML={{
                                __html: last_name,
                            }}
                        />
                    </TeamDescription>
                </Transition>
            </TeamLink>
        )
    })

    return <Team>{items}</Team>
}

const renderBottomMain = data => {
    return (
        <Main>
            <Intro
                dangerouslySetInnerHTML={{
                    __html: data.acf.introduction,
                }}
            />
            <Description
                dangerouslySetInnerHTML={{
                    __html: data.acf.text,
                }}
            />
        </Main>
    )
}

const Person = ({ pageContext, ...props }) => {
    console.log('pageContext', pageContext)

    return (
        <Layout
            meta={pageContext && pageContext.acf.seo}
            mobileHeaderTheme={'light'}
            desktopHeaderTheme={'dark'}
        >
            <Wrapper>
                <Container>
                    {/* Top */}
                    {pageContext && (
                        <Top>
                            <Fade delay={800}>
                                <Grid>
                                    {renderTopSidebar(pageContext)}
                                    {renderTopMain(pageContext)}
                                </Grid>
                            </Fade>
                        </Top>
                    )}

                    {/* Bottom */}
                    {pageContext && (
                        <Bottom>
                            <Fade delay={1200}>
                                <Grid>
                                    {renderBottomSidebar(pageContext)}
                                    {renderBottomMain(pageContext)}
                                </Grid>
                            </Fade>
                        </Bottom>
                    )}

                    <MoreTeam>
                        {/* More Team */}
                        {pageContext && pageContext.acf.more_team && (
                            <Fade>
                                <Grid>
                                    <Sidebar />
                                    <Main>
                                        <Heading>Meet more of our team</Heading>
                                        {renderMoreTeam(pageContext)}
                                    </Main>
                                </Grid>
                            </Fade>
                        )}
                    </MoreTeam>
                </Container>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.h1``
const MobileHeading = styled.h2``
const Subheading = styled.h2``
const Description = styled.div``
const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const BGImage = styled.div`
    background-image: url(${props => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`
const ExternalLink = styled.a``

// Layout

const Wrapper = styled.div`
    padding-top: 190px;
    background-color: #67676a;

    ${media.tabletLandscape`
        padding-top: 140px;
    `}

    ${media.tablet`
        padding-top: 100px;
    `}
`
const Grid = styled.div``
const Sidebar = styled.div``
const Main = styled.div``
const Container = styled.div`
    ${container}
    ${padding}

	${Grid} {
        display: flex;
        max-width: 100%;

        ${media.phone`
            flex-direction: column;
        `}
    }

    ${Sidebar}, ${Main} {
        box-sizing: border-box;
    }


	/* Sidebar  */
    ${Sidebar} {
        width: 33.33%;

        ${media.phone`
            width: 100%;
        `}
    }


	/* Main */
    ${Main} {
        width: 66.66%;

        ${media.phone`
            width: 100%;
        `}
    }
`

// Top

const Top = styled.div`
    ${Sidebar} {
        padding-left: 20px;
        border-left: 1px solid ${grey1};

        ${media.phone`
            padding-left: 0;
            border-left: none;
        `}

        ${MobileHeading} {
            ${heading1};
            color: white;
        }

        ${Heading} {
            ${heading2};
            line-height: 1;
            color: white;

            ${media.phone`
                margin-top: 90px;
            `}
        }
    }

    ${Main} {
        ${Heading} {
            padding-left: 20px;
            padding-bottom: 80px;
            border-left: 1px solid ${grey1};
            ${heading1};
            line-height: 1;
            color: white;

            ${media.tabletLandscape`
                padding-bottom: 64px;
            `}

            ${media.phone`
                margin-top: 16px;
                padding-left: 0;
                padding-bottom: 0;
                border-left: none;
            `}
        }

        ${Image}, ${BGImage} {
            margin-top: 20px;
            height: 540px;

            ${media.tabletLandscape`
                height: 400px;
            `}

            ${media.phone`
                height: 220px;
                margin-top: 16px;
            `}
        }
    }
`

// Bottom

const Intro = styled.div``
const Bottom = styled.div`
    margin-top: 50px;

    ${media.phone`
        margin-top: 32px;
    `}

    ${Sidebar} {
        padding-right: 90px;

        ${media.tabletLandscape`
            padding-right: 60px;
        `}

        ${media.phone`
            order: 9;
            padding-right: 0;
        `}

        ${Heading} {
            ${heading3};
            color: white;
        }
    }

    ${Main} {
        ${Intro} {
            ${heading2};
            color: ${grey3};
        }

        ${Description} {
            margin-top: 1.4em;
            padding-bottom: 64px;
            ${body};
            color: white;

            ${media.tabletLandscape`
                padding-bottom: 32px;
            `}
        }
    }
`

// Bottom Sidebar Stuff

const Phone = styled.div``
const Address = styled.div``
const Item = styled.div``
const Info = styled.div`
	margin-top: 100px;

    ${media.tabletLandscape`
        margin-top: 42px;
    `}

	${media.phone`
		margin-top: 0;
	`}

	${Phone}, ${Address}, ${Item} {
		padding-top: 16px;
		padding-bottom: 24px;
		border-top: 2px solid ${grey1};

		${Heading} {
			${subheading2};
			color: white;
		}
	}

	${Phone}, ${Address} {
        ${Heading} {
            margin-bottom: 8px;
        }

		${ExternalLink} {
			${heading2};
			color: #bfb8b4;
			transition: color 0.15s ease;

			&:hover {
				color: white;
			}

            ${media.phone`
                color: white;
            `}
		}
	}

    ${Item} ${ExternalLink} {
        ${links};
        color: #bfb8b4;
        border-bottom-color: #bfb8b4;
        display: inline;
        line-height: 1.6;
        transition: border-color 0.15s ease, color 0.15s ease;

        ${media.phone`
            font-size: 12px;
            border-bottom-color: transparent;
            color: white;
        `}

        &:hover {
            color: white;
            border-color: white;
        }
    }
`

// Bottom More Team Layout

const Team = styled.div``
const MoreTeam = styled.div`
    width: 100%;
    padding-bottom: 120px;

    ${media.tabletLandscape`
        padding-bottom: 80px;
    `}

    ${media.phone`
        margin-top: 50px;
        padding-bottom: 42px;
    `}

    ${Heading} {
        padding-top: 12px;
        border-top: 2px solid ${grey1};
        ${subheading2};
        color: white;

        ${media.phone`
            padding-top: 12px;
        `}
    }

    ${Team} {
        display: flex;
        flex-flow: row wrap;
        margin: 42px -10px -40px;

        ${media.tabletLandscape`
            margin: 32px -10px -32px;
        `}

        ${media.phone`
            margin: 32px -5px -32px;
        `}
    }
`

// Bottom More Team Items

const ImageWrapper = styled.div``
const TeamImage = styled(ProgressiveImage)`
    overflow: hidden;
`
const TeamBGImage = styled(motion.div)`
    background-image: url(${props => props.image});
    ${bgImage};
    height: 100%;
`
const Transition = styled(motion.div)`
    height: 100%;
`
const TeamDescription = styled(motion.div)``
const Roles = styled.div``
const FirstName = styled(motion.div)``
const LastName = styled(motion.div)``
const TeamLink = styled(Link)`
    position: relative;
    display: inline-block;
    width: 25%;
    margin-bottom: 40px;
    padding: 0 10px;
    box-sizing: border-box;
    overflow: hidden;

    ${media.tabletLandscape`
        width: 33.33%;
        margin-bottom: 32px;
    `}
    
    ${media.tablet`
        width: 50%;
    `}

    ${media.phone`
        padding: 0 5px;
    `}

    ${ImageWrapper} {
        height: 200px;
        overflow: hidden;

        ${media.desktopSmall`
            height: 180px;
        `}

        ${media.phone`
            height: 150px;
        `}
    }

    ${TeamDescription} {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
        box-sizing: border-box;
        color: white;
        background-color: #67676a;

        ${media.tablet`
            display: block;
        `}
    }

    ${Roles} {
        margin-top: 16px;
        ${subheading2};

        ${media.phone`
            margin-top: 12px;
        `}
    }

    ${FirstName}, ${LastName} {
        ${heading2};
        line-height: 0.75;
        margin-bottom: 10px;
        border-bottom: 1px solid transparent;

        ${media.tablet`
            display: inline-block;
            margin-bottom: 0;
        `}
    }

    ${FirstName} {
        margin-top: 12px;

        ${media.tablet`
            margin-right: 4px;
        `}
    }
`

// Animations

const animatedImage = {
    initial: {
        scale: 1.1,
    },
    variants: {
        hover: {
            scale: 1.01,
        },
    },
    transition: { duration: 1 },
}

const animatedDescription = {
    variants: {
        hover: {
            y: '-8px',
        },
    },
    transition: { duration: 0.3 },
}

const animatedLink = {
    variants: {
        hover: {
            borderBottomColor: `#ffffff`,
        },
    },
    transition: { duration: 0.3 },
}
export default Person
